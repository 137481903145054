import React, { Component } from 'react';
import Grid from '@mui/material/Grid';

const Portfolio = ({data})=>{
  if(!data) return null;

  return (
    <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
            <h1>Check out some projects I've been a part of.</h1>
            <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                <Grid
                  container
                  justifyContent="space-around"
                >{
                  data.projects.map(p => (
                    <Grid item key={p.url}>
                      <a href={p.url} title={p.title}>
                        {p.title}
                      </a>
                    </Grid>
                  ))
                }</Grid>
            </div>
          </div>
      </div>
    </section>
  );
}

export default Portfolio;
